import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import PlainTextCell from 'components/PlainTextCell';

import { DEALER_TEAM, FLEET } from 'utils/SentinelTeam/constants';

const SOURCE_TYPE_LABELS = {
  [DEALER_TEAM]: t`Service Provider`,
  [FLEET]: t`Fleet`,
};

export default function TeamTypeCell({
  rowData: { teamType, scheduledForDeletionAt },
}) {
  return (
    <PlainTextCell
      color={scheduledForDeletionAt ? 'alaskanHusky' : undefined}
      topLine={SOURCE_TYPE_LABELS[teamType]}
    />
  );
}

TeamTypeCell.propTypes = {
  rowData: PropTypes.shape({
    teamType: PropTypes.string,
    scheduledForDeletionAt: PropTypes.string,
  }).isRequired,
};
